@import url("https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.1.1/tailwind.min.css");
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

code {
  font-family: "Roboto", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-1q39md6-MuiButtonBase-root-MuiButton-root {
  text-transform: none !important;
}

.display-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ticker-container {
  /* width: 100%; */
  padding: 0.5em;
  background-color: black;
  overflow-x: auto;
}

.ticker-streamer {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ticker-context {
  min-width: 200px;
  padding: 0.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1em;
}

.ticker-identifier {
  font-size: 1.5rem;
}

.text-red {
  color: rgb(255, 53, 53) !important;
}

.text-teal {
  color: rgb(0, 211, 148) !important;
}

.font-lexend {
  font-family: "Roboto", sans-serif !important;
}

.ps-container {
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
}

.ps-load {
  height: calc(100vh - 128px - 1em);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ps-spinner {
  color: black;
  width: 50px;
}

.ps-cl-container {
  width: 100%;
  /* border: 1px solid black; */
}

.ps-cl-header {
  width: 100%;
  border: 1px solid black;
  padding: 0.5em 1em;
  display: flex;
  background-color: rgb(230, 230, 230);
}

.ps-cl-body {
  width: 100%;
  overflow: auto;
}

.ps-cl-tbl {
  /* width: 100%; */
  /* overflow: auto; */
}

.w-full {
  width: 100% !important;
}

.bg-red {
  background-color: rgba(252, 165, 165, 0.75) !important;
  color: rgb(255, 51, 51) !important;
}

.bg-green {
  background-color: rgba(110, 231, 183, 0.699) !important;
  color: rgb(14, 146, 93) !important;
}

.ps-cl-sqoff {
  color: rgb(41, 113, 223) !important;
  text-decoration: underline;
}

.ps-cl-place-order {
  cursor: pointer;
}

.plo-select {
  width: 100% !important;
  padding: 10px 10px;
  outline: none;
}

@media (max-width: 450px) {
  .ticker-streamer {
    justify-content: start;
  }
}

.accordion-button:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}


.ob-container {
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
}

.ob-load {
  height: calc(100vh - 128px - 1em);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ob-user-info {
  width: 100%;
  padding: 10px;
  background-color: rgb(219, 219, 219);
  font-family: "Roboto", sans-serif !important;
  border: 1px solid #5e5b5b;
}

.ob-user-broker {
  width: 100%;
  padding: 10px;
  font-family: "Roboto", sans-serif !important;
  border: 1px solid #5e5b5b;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.bg-accordian-header>.accordion-button {
  background-color: rgb(180, 180, 180) !important;
}

.accordian-name-header,
.accordian-name-header>button {
  background-color: rgb(144, 238, 255) !important;
  color: black !important;
}

/* V2 Positions UI */
.ps-table {
  border: 1px solid #858b9c;
  margin-bottom: 2rem;
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
}

.supreme-blue {
  background-color: #c6d5e8;
}

.button-bg-blue {
  background-color: #304e6e;
}

.ps-button-label-wrapper {
  display: inline-flex;
  align-items: center;
}

th,
tr,
td {
  font-weight: 400;
  font-size: 11pt;
}

.order-headers-bg {
  background-color: rgb(245, 245, 245) !important;
  font-size: 10pt !important;
}

.disabled {
  cursor: not-allowed;
}

.ps-controls-btn {
  background-color: #e27a3b;
  border: #e27a3b;
  color: #fff;
  padding: 6px 20px;
  margin-right: 3%;
  font-size: 10pt !important;
}

.zindexformodal {
  z-index: 9999 !important;
}

.MuiTablePagination-selectLabel {
  margin-bottom: 0px !important;
}

.MuiTablePagination-displayedRows {
  margin-bottom: 0px !important;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white !important;
  background: url('data:image/svg+xml;utf8,<svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M6 9l6 6 6-6"></path></svg>') no-repeat right 0.1rem center/1.5rem 1.5rem;
}


.custom-modal .modal-content {
  width: 400px !important;
  font-size: 13px !important;
}


.modal-dialog {
  justify-content: center !important;
}

.large-icon {
  font-size: 28px !important;
  font-weight: bolder !important;
  /* margin-left: 0.5rem !important; */
  /* margin-right: 5px !important; */
  cursor: pointer !important;
}

.icon-button {
  font-size: 1.5rem;
  cursor: pointer;
  background: transparent;
  color: black;
  width: max-content;
}

.icon-button:focus {
  outline: none;
}

.margin-table {
  width: 100% !important;
  border-collapse: collapse !important;
  margin-top: 10px !important;
}

.margin-thead {
  display: table-header-group !important;
  vertical-align: middle !important;
  unicode-bidi: isolate !important;
  border-color: inherit !important;
}

.margin-tr {
  display: table-row !important;
  vertical-align: middle !important;
  unicode-bidi: isolate !important;
  border-color: inherit !important;
}

.margin-th {
  display: table-cell !important;
  font-weight: bold !important;
  border: 1px solid #5e5b5b !important;
  background-color: #f2f2f2 !important;
  padding: 8px !important;
  font-size: 13px !important;
}

.custom-margin-th {
  display: table-cell !important;
  border: 1px solid #5e5b5b !important;
  background-color: #f2f2f2 !important;
  padding: 8px !important;
  font-size: 13px !important;
}

.margin-tbody>.margin-tr>td {
  display: table-cell;
  border: 1px solid #5e5b5b !important;
  padding: 8px;
  font-size: 13px;
}

.table-border-black {
  /* border: 1px solid #0f0d0d !important; */
  padding: 8px 0px !important;
  display: flex !important;
  flex-direction: row-reverse !important;
}

/* .table-total-black{
  border: 1px solid #0f0d0d !important;
  padding: 8px 0px !important;
} */

.green-row {
  background-color: #99d499;
  ;
}

.red-row {
  background-color: #d17575;
}

/* .form-control:focus{
  box-shadow: none !important;
  border-color: #dee2e6 !important;
}

.form-control {
  border-radius: 0px !important;
  border: 0px !important;
  border-right: 1px solid #dee2e6 !important;
  border-left: 1px solid #dee2e6 !important;
} */

.index-input {
  border-radius: 0px !important;
  border: 1px solid #dee2e6 !important;
  height: 30px !important;
}

.index-input:focus {
  box-shadow: none !important;
  border-color: #dee2e6 !important;
}

.full-width {
  width: 100% !important;
}

.boot-btn {
  background-color: transparent !important;
  color: #0f0d0d !important;
  border-color: #dee2e6 !important;
}

.all-normal-text {
  font-size: 13px !important;
}

.check .form-check {
  margin-bottom: 0rem !important;
  min-height: 0px !important;
  width: 250px !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.loss-color {
  color: #FF0000 !important;
}

.profit-color {
  color: #66b20f !important;
}

.profit-and-loss-bg {
  background-color: #eff6ff !important;
}

.table-head {
  background-color: #f2f2f2 !important;
  padding: 8px !important;
  font-size: 13px !important;
}

.button-style {
  font-size: 13px !important;
  background-color: #e27a3b !important;
  color: #ffffff !important;
  border: 1px solid #e27a3b !important;
  border-radius: 0px !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-bg-type: #ffffff !important;
}

.hide-dropdown {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  background: none !important;
}

.ltp-bg {
  background-color: #f2f2f2 !important;
  padding: 4px 8px !important;
  font-size: 13px !important;
  color: #000000 !important;
}

.distance-bg {
  background-color: #f2f2f2 !important;
  padding: 4px 0px !important;
  font-size: 13px !important;
  color: #000000 !important;
}

.dark {
  color: #000000 !important;
}

.MuiAlert-colorSuccess {
  background-color: #cfffc6 !important;
  color: #000000 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

.MuiAlert-colorError {
  background-color: #ffdbdb !important;
  color: #000000 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

.navbar-style {
  font-size: 13px !important;
  cursor: pointer !important;
}

.input-style {
  font-size: 13px !important;
  color: #000000 !important;
  background-color: whitesmoke !important;
  height: 30px !important;
  border: 1px solid #ccc;
}

.input-style:focus {
  outline: none !important;
}

.input-text {
  font-size: 13px !important;
  color: #000000 !important;
}

.form-error {
  color: red !important;
  font-size: 13px !important;
}

.custom-modals {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 400px;
  max-height: 80vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  font-size: 13px !important;
  overflow-y: auto !important;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.modal-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: move;
  background-color: #343a40;
  color: #ffffff !important;
  padding: 16px !important;
  /* margin-top: 10px; */
  border-bottom: 1px solid #dee2e6;
  font-size: 13px !important;
}

.modal-titles {
  margin: 0;
  font-size: 13px;
}

.closes {
  font-size: 21px;
  line-height: 1;
  color: #ffffff;
  text-shadow: 0 1px 0 #fff;
  /* opacity: 0.5; */
  background: none;
  border: none;
}

.modal-bodies {
  padding: 16px !important;
  font-size: 13px !important;
}

.p {
  font-size: 13px !important;
  margin-bottom: 0px !important;
}

.no-margin {
  margin: 0px !important;
}



.commands-modal .modal-dialog {
  max-width: 600px;
}

.commands-modal-header .btn-close {
  background-color: #ffffff !important;
  color: #ffffff !important;
}

.btn-close {
  background-color: #ffffff !important;
  color: #ffffff !important;
}

.modal-dialog .modal-content {
  border: none !important;
}

.commands-modal-header {
  background-color: #343a40;
  color: #fff;
  border-bottom: none;
}

.commands-modal-body {
  background-color: #f8f9fa;
  padding: 0;
  max-height: 400px;
  overflow-y: auto;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
}

.chat-box {
  flex: 1;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  max-height: 250px;
  overflow-y: auto;
}

.chat-messages {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.chat-message {
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #e9ecef;
  margin-bottom: 10px;
}

.message-content {
  color: #333;
  font-size: 14px;
}

.no-comments {
  color: #999;
  text-align: center;
  margin-top: 20px;
}

.chat-input-form {
  display: flex;
  align-items: center;
}

.chat-input-group {
  flex: 1;
  display: flex;
}

.chat-input {
  flex: 1;
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #ced4da;
  margin-right: 10px;
}

.send-button {
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 20px;
  padding: 8px 15px;
}

.commands-modal-footer {
  background-color: #f8f9fa;
  border-top: none;
  display: flex;
  justify-content: flex-end;
}

.icon-hover {
  color: rgb(107, 105, 105);
}

.icon-hover:hover {
  color: #ff7f50;
  transform: scale(1.1);
  transition: transform 0.2s, color 0.2s;
}

.delete-modal .modal-dialog {
  width: 350px !important;
}

.remove-text-box {
  background: none !important;
  border: none !important;
  padding: 0rem 0rem .375rem 0rem !important;
}

.remove-text-box:focus {
  outline: none !important;
}

.dropdown-wrapper {
  position: relative;
}

.custom-select-dropDowm:focus {
  outline: none !important;
}

.orderbook-cancel-btn {
  color: #ffffff;
  background-color: #ff7f50;
  width: 100%;
  height: 100%;
  padding: 0.25rem 0.5rem;
}

/* Custom CheckBox => Radio button */
.styled-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid gray;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  background-color: #fff;
  transition: all 0.3s ease;
}

.styled-checkbox:checked {
  background-color: #007bff;
  border-color: gray;
}

.styled-checkbox:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 5px;
  height: 5px; */
  border: 1px solid gray;
  background-color: #fff;
  border-radius: 50%;
}
